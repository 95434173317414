.contact-data {
  background-color: $color-white;
  @include shadow;
  @include contain-floats;
  @include font-size-small;

  &__item {
    border-bottom: 1px solid $color-lightgrey;
    float: left;
    padding: 30px;
    width: 100%;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  &__address,
  &__item {
    a {
      color: $color-blue;
      text-decoration: none;
      @include medium-font;
    }
  }

  &__address,
  &__map {
    padding: 40px;
    width: 100%;
  }

  &__address {
    @include font-size-content;
  }

  &__map {
    background-position: center;
    background-size: cover;
    min-height: 250px;
  }

  @include media-query-sm-up {
    margin-left: -50px;
    margin-right: -50px;
    padding: 0;
    @include font-size-content;

    &__address,
    &__map {
      float: left;
      padding: 0;
      width: 50%;
    }

    &__item {
      border-bottom: 0;
      border-right: 1px solid $color-lightgrey;
      margin-bottom: 0;
      padding: 50px;
      text-align: center;
      width: 50%;

      &:last-of-type {
        border-right: 0;
      }
    }
  }
}
