@mixin font-size-heading {
  font-size: 24px;
  line-height: 36px;
}

@mixin font-size-content {
  font-size: 16px;
  line-height: 32px;
}

@mixin font-size-small {
  font-size: 14px;
  line-height: 20px;
}

@mixin bold-font {
  font-weight: 700;
}

@mixin medium-font {
  font-weight: 400;
}

@mixin light-font {
  font-weight: 300;
}

@mixin reset-floats {
  clear: both;
  float: none;
}

@mixin contain-floats {
  &::before {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

@mixin smooth {
  transition: 0.2s ease-in;
}

@mixin shadow {
  box-shadow: 0 10px 25px 0 rgba($color-black, 0.09);
}

@mixin inversed-shadow {
  box-shadow: 0 -10px 25px 0 rgba($color-black, 0.09);
}
