.schedule {
  border-left: 1px solid $color-grey;
  @include font-size-small;
  margin-left: 20px;
  margin-top: 10px;

  &__row {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding: 5px 0;

    &::before {
      background-color: $color-white;
      border: 1px solid $color-grey;
      border-radius: 50%;
      content: '';
      height: 8px;
      left: -6px;
      position: absolute;
      top: 10px;
      width: 8px;
    }
  }

  &__time {
    flex-basis: 35%;
    padding: 0 20px;
    @include bold-font;
  }

  &__description {
    padding: 0 20px;
  }

  @include media-query-sm-up {
    &__row {
      align-items: flex-start;
      flex-direction: row;
      justify-content: stretch;
    }

    &__time {
      flex-basis: 35%;
    }
  }
}
