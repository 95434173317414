// greyscale
$color-black: #000;
$color-grey: #a2a2a2;
$color-lightgrey: #e9e9e9;
$color-white: #fff;

// brand colors
$color-blue: #2093c6;
$color-lightblue: #a3dffa;
$color-magenta: #aa214e;
$color-pink: #f14f84;
$color-green: #099183;
$color-lightgreen: #5bc5ba;
$color-yellow: #ffef75;
$color-lightyellow: #fffbdc;


//$assets-prefix: '';
$assets-prefix: '/';

$navbar-height: 120px;
