.text {
  @include light-font;
  @include font-size-content;
  margin: 60px auto;

  &--center {
    text-align: center;
  }

  &__heading {
    @include font-size-heading;
  }

  &__content {
    margin: 0;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding-left: 20px;
  }

  &__list-item {
    position: relative;

    &::before {
      color: $color-grey;
      content: '.';
      font-size: 36px;
      left: -15px;
      position: absolute;
      top: -10px;
    }
  }
}
