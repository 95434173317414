.button {
  border-radius: 24px;
  display: inline-block;
  height: 48px;
  line-height: 26px;
  margin: 15px;
  min-width: 200px;
  padding: 10px 35px;
  text-align: center;
  text-decoration: none;
  @include medium-font;

  &--small {
    font-size: 14px;
    height: 36px;
    padding: 5px 15px;
  }

  &--green {
    background-color: $color-green;
    border-color: $color-green;
    color: $color-green;
  }

  &--blue {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-blue;
  }

  &--magenta {
    background-color: $color-magenta;
    border-color: $color-magenta;
    color: $color-magenta;
  }

  &--green,
  &--blue,
  &--magenta {
    &:hover {
      background-color: $color-white;
      border-color: $color-white;
      color: $color-white;
    }
  }

  &--filled {
    color: $color-white;

    &:hover {
      color: $color-black;
    }
  }

  &--contour {
    background: transparent;
    border: 2px solid;

    &:hover {
      background: transparent;
    }
  }
}
