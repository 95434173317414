.jumbotron {
  align-items: center;
  background: linear-gradient(90deg, $color-pink, $color-yellow);
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: center;
  text-align: center;
  width: 100%;

  &__button {
    display: inline-block;
	}
  
  &__text {
    color: $color-white;

    h1 {
      @include bold-font;
      font-size: 24px;
      line-height: 30px;
		}
    
    h2 {
      @include medium-font;
      font-size: 16px;
      line-height: 24px;
		}
	}

  @include media-query-md-up {
    &__text {
      h1 {
        font-size: 36px;
        line-height: 40px;
      }
      
      h2 {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
