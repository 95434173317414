.partners {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 100%;

  &__item {
    margin: 15px;
  }

  &__image {
    &--square {
      max-height: 120px;
      max-width: 120px;
    }

    &--horizontal {
      max-height: 80px;
      max-width: 100%;
    }

    &--vertical {
      max-height: 130px;
    }
  }
}

.iph-logo {
  position: absolute;
  bottom: -70px;
  left: -13%;
  max-width: 80%;
  z-index: -1;
  pointer-events: none;
  opacity: .03;

  &--mini {
    float: right;
    display: inline-block;
    max-height: 140px;
    width: auto;
    margin-left: 25px;
    margin-bottom: 25px;
  }
}
