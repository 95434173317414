.ce {
  &-paragraph {
    margin: 0;
    @include light-font;
    @include font-size-small;
    margin-bottom: 1em;
    text-align: center;

    a {
      color: $color-white;
    }
  }

  &-heading {
    @include light-font;
    @include font-size-content;
    margin: 0;
    text-align: center;
  }

  &-banner {
    background-color: rgba($color-magenta, 0.9);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    bottom: 0;
    color: $color-white;
    font-size: 1em;
    left: 50%;
    padding: 15px 20px 10px;
    position: fixed;
    transform: translate(-50%, 0);
    width: calc(100% - 20px);
    z-index: 150;
  }

  &-accept {
    background-color: $color-white;
    border-radius: 20px;
    color: $color-magenta;
    display: block;
    margin: 10px auto;
    padding: 8px 15px;
    text-align: center;
    text-decoration: none;
    transition: 0.2s ease-in;
    width: 160px;
    z-index: 20;
    @include font-size-small;
    @include light-font;
    @include shadow;
    
    &:hover {
      background-color: $color-pink;
      color: $color-white;
    }
  }

  &-dismiss {
    border-radius: 50%;
    color: $color-white;
    display: inline-block;
    font-size: .8em;
    height: 20px;
    line-height: 1.62;
    position: absolute;
    right: 1em;
    text-align: center;
    text-decoration: none;
    top: 1em;
    width: 20px;

    &:hover {
      background-color: $color-magenta;
    }
  }

  @include media-query-md-up {
    &-banner {
      max-width: 80vw;
      padding: 25px 60px 10px;
      width: calc(100% - 40px);
    }

    &-accept {
      position: absolute;
      right: 60px;
      top: 50%;
      transform: translate(0, -50%);
    }

    &-paragraph {
      max-width: calc(100% - 180px);
      text-align: left;
    }

    &-heading {
      text-align: left;
    }
  }
}
