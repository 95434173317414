.cta {
  color: $color-white;
  @include light-font;

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  &--magenta-pink {
    background: linear-gradient(90deg, $color-magenta, $color-pink);
  }

  &--blue-lightgreen {
    background: linear-gradient(90deg, $color-blue, $color-lightgreen);
  }

  &--lightgreen-lightblue {
    background: linear-gradient(90deg, $color-lightgreen, $color-lightblue);
  }

  &__link {
    @include medium-font;
    color: $color-white;
    text-decoration: none;
  }

  &__button {
    margin: 10px 0;
  }

  &__text + img {
    display: none;
  }
  
  @include media-query-sm-up {
    .container {
      flex-direction: row;
      justify-content: space-between;
    }

    &__text + img {
      display: block;
    }
  }
}
