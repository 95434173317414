.recruitment-process {
  &__container {
    display: block;
  }

  &__steps,
  &__summary {
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__steps {
    display: flex;
  }

  &__summary {
    background: linear-gradient(90deg, $color-green, $color-lightgreen);
    color: $color-white;
    display: none;
  }

  &__step,
  &__summary-step {
    padding: 20px;
    text-align: center;
    width: 100%;
  }

  &__step {
    align-items: center;
    background-color: lighten($color-yellow, 20%);
    display: flex;
    height: 100%;
    justify-content: center;
    margin-bottom: 50px;
    min-height: 250px;
    padding-top: 40px;
    @include light-font;
    @include font-size-small;

    &:last-of-type {
      text-align: left;
    }
  }

  &__summary-step {
    &::after {
      background: url('/images/icons/arrow-right.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: 20px;
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translate(0, -50%);
      width: 20px;
    }

    &:last-of-type::after {
      display: none;
    }
  }

  &__step-number {
    color: $color-yellow;
    font-size: 70px;
    left: 15px;
    position: absolute;
    top: 0;
    @include bold-font;
  }

  @include media-query-md-up {
    &__steps,
    &__summary {
      align-items: flex-start;
      flex-direction: row;
      justify-content: stretch;
      margin-left: -80px;
      margin-right: -80px;
    }

    &__steps {
      background-color: lighten($color-yellow, 20%);
    }

    &__summary {
      display: flex;
    }

    &__step,
    &__summary-step {
      width: 33.333%;
    }

    &__step {
      background: none;
      border-right: 5px solid $color-lightgrey;
      margin-bottom: 0;

      &:last-of-type {
        border-right: 0;
      }
    }
  }
}
