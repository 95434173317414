$slider-item-size: 130px;

.professor {
  display: none;
  margin-top: 40px;
  padding-bottom: 80px;

  &__avatar {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    height: 160px;
    margin: 0 auto 50px;
    width: 160px;
  }

  &__name {
    padding: 0 50px;
    text-align: center;
  }

  &__buttons {
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__button {
    background: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 36px;
    opacity: 0.8;
    outline: none;
    padding: 0;
    position: absolute; 
    top: -50%;
    transition: 0.2s ease-in;
    width: 36px; 
    z-index: 40;

    &.prev {
      background-image: url('/images/icons/slider-arrow-left.svg');
      left: 0;
    }

    &.next {
      background-image: url('/images/icons/slider-arrow-right.svg');
      right: 0;
    }
  }
}

.professors {
  width: 100%;
  z-index: 10;

  &.active {
    background-color: lighten($color-lightgrey, 6%);
  }

  &__list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 30px 80px;
  }

  &__item {
    cursor: pointer;
    max-width: $slider-item-size + 40px;
    padding: 20px;
    text-align: center;
  }

  &__avatar {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
    height: $slider-item-size;
    width: $slider-item-size;
    @include shadow;

    .professors__item.active & {
      border: 1px solid $color-magenta;
      box-shadow: 0 0 0 6px rgba($color-pink, 0.2), 0 0 0 12px rgba($color-pink, 0.2), 0 0 0 18px rgba($color-pink, 0.2);
    }

    &:hover {
      border: 1px solid $color-blue;
      box-shadow: 0 0 0 6px rgba($color-lightblue, 0.2), 0 0 0 12px rgba($color-lightblue, 0.2), 0 0 0 18px rgba($color-lightblue, 0.2);
    }
  }

  &__name {
    max-width: 100%;
    white-space: wrap;
    @include font-size-small;
  }
}

@include media-query-sm-up {
  .professor {
    margin-top: 100px;
    padding-left: 260px;

    &__avatar {
      height: 200px;
      left: 0;
      position: absolute;
      top: 0;
      width: 200px;
    }

    &__name {
      padding: 0 65px;
      text-align: left;
    }
  }
}
