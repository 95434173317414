@import 'fonts';
@import 'variables';
@import 'mediaqueries';
@import 'mixins';
@import 'buttons';
@import 'text';
@import 'cta';
@import 'circle';

body, html {
  font-family: $main-font;
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0;
  scroll-behavior: smooth;
  * {
    box-sizing: border-box;
    position: relative;
  }
}

.page {
  padding-top: $navbar-height;
}

.section {
  padding: 80px 0;

  &--lightgreen {
    background-color: lighten($color-lightgreen, 35%);
  }

  &--lightblue {
    background-color: lighten($color-lightblue, 15%);
  }
}

.container {
  display: block;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

.contain-floats {
  @include contain-floats;
}

.flex {
  display: flex;

  &--center {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

@include media-query-sm-up {
  .container {
    width: calc(100% - 80px);
  }
}

@include media-query-md-up {
  .container {
    width: 768px;
  }
}

@include media-query-lg-up {
  .container {
    width: 1024px;
  }
}

@include media-query-xl-up {
  .container {
    width: 1200px;
  }
}

@import 'partials/hamburger';
@import 'partials/jumbotron';
@import 'partials/navigation';
@import 'partials/quotation';
@import 'partials/partners';
@import 'partials/programme-step';
@import 'partials/recruitment-process';
@import 'partials/schedule';
@import 'partials/professors';
@import 'partials/contact-data';

@import 'aos';
@import 'cookies';
