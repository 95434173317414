.navbar {
  align-items: center;
  background-color: $color-white;
  display: flex;
  height: $navbar-height;
  justify-content: space-between;
  left: 0;
  padding: 0 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  @include shadow;

  @include media-query-sm-up {
    padding: 0 50px;
  }
}

.logo {
  align-items: center;
  display: flex;
  z-index: 1005;

  &__image {
    height: auto;
    width: 150px;
  }

  &__facebook {
    background-image: url('/images/icons/facebook.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 30px;
    margin-left: 15px;
    opacity: 0.7;
    width: 30px;
    @include smooth;

    &:hover {
      opacity: 1;
    }
  }

  @include media-query-sm-up {
    &__image {
      width: 180px;
    }

    &__facebook {
      margin-left: 25px;
    }
  }
}

.main-menu {
  &__list {
    background-color: $color-white;
    display: none;
    height: 100%;
    left: 0;
    list-style: none;
    overflow: scroll;
    padding: 0;
    padding-top: $navbar-height;
    position: fixed;
    top: 0;
    width: 100%;
  }

  &__item {
    display: block;
    margin: 0 auto 30px;
    text-align: center;
  }

  &__icon {
    display: block;
    margin: 0 auto;
    max-height: 40px;
    max-width: 40px;
  }
  
  &__link {
    color: $color-black;
    display: inline-block;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    transition: .15s ease-in;
    @include font-size-small;
    @include medium-font;

    &:hover {
      color: $color-pink;
    }
  }

  &__trigger {
    display: block;
  }

  @include media-query-sm-up {
    &__trigger {
      display: none;
    }

    &__list {
      display: block;
      height: auto;
      overflow: hidden;
      padding-top: 0;
      position: relative;
      width: auto;
    }

    &__item {
      float: left;
      margin-bottom: 0;
      margin-left: 35px;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}
