.programme-step {
  padding: 50px 0;
  @include smooth;

  &__number {
    color: $color-lightgrey;
    font-size: 150px;
    left: 40px;
    position: absolute;
    top: -30px;
    @include bold-font;
    @include smooth;
  }

  &__head {
    @include contain-floats;
    cursor: pointer;
  }

  &__heading {
    margin: 0;
    text-transform: uppercase;

    &::before {
      background-color: $color-white;
      bottom: -8px;
      content: '';
      display: block;
      left: -8px;
      position: absolute;
      right: -8px;
      top: -8px;
      z-index: -1;
    }
  }

  &__revealer {
    appearance: none;
    background: transparent;
    border: 0;
    color: $color-grey;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    min-height: 36px;
    padding: 10px;
    @include bold-font;
    @include smooth;

    &::after {
      background: url('/images/icons/arrow-down.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: 20px;
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translate(0, -50%);
      width: 20px;
    }
  }

  &__point {
    margin: 0;

    &.active {
      @include bold-font;
    }
  }

  &__details {
    display: none;
  }

  &.active {
    background-color: rgba($color-lightblue, 0.4);
    margin: 0;
    padding: 80px 0;

    .programme-step__number {
      color: $color-blue;
      left: 15px;
    }
    
    .programme-step__head::before {
      display: none;
    }

    .programme-step__heading::before,
    .programme-step__revealer::before { 
      display: none;
    }

    .programme-step__point {
      @include bold-font;
      margin-top: 20px;
    }

    .programme-step__details {
      display: block;
    }

    .programme-step__revealer {
      color: $color-blue;

      &::after {
        background: url('/images/icons/arrow-up.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

  @include media-query-sm-up {
    &__head {
      &::before {
        background-color: $color-lightgrey;
        content: '';
        height: 2px;
        position: absolute;
        right: 120px;
        top: 50%;
        transform: translate(0, -50%);
        width: calc(100% - 180px);
        z-index: -1;
      }
    }

    &__number {
      font-size: 220px;
      left: -30px;
      top: -30px;
    }

    &__revealer {
      float: right;
    }

    &__heading {
      float: left;
    }

    &.active {
      .programme-step__number {
        color: $color-blue;
        left: -125px;
      }

      .programme-step__revealer {
        color: $color-blue;

        &::after {
          background: url('/images/icons/arrow-up.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}
