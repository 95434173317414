.quotation {
  @include light-font;
  @include font-size-content;
  font-style: italic;

  &__content {
    &::before {
      @include bold-font;
      color: $color-lightgrey;
      content: '„';
      font-size: 350px;
      font-style: normal;
      left: -80px;
      position: absolute;
      top: -130px;
      z-index: -1;
    }
  }

  &__author {
    text-align: right;

    strong {
      @include medium-font;
      font-style: normal;
    }
  }
}
