$sizes: 100 200 300 350 400 450 500 700;

.circle {
  border: 16px solid;
  border-color: $color-grey;
  border-radius: 50%;
  display: none;
  height: 100px;
  opacity: .12;
  pointer-events: none;
  position: absolute;
  width: 100px;

  &--left {
    left: 80px;
  }

  &--right {
    right: 80px;
  }

  &--center {
    left: 50%;
    transform: translate(-50%, 0);
  }

  @each $size in $sizes {
    &--#{$size} {
      height: #{$size}px;
      width: #{$size}px;
    }
  }

  &--lightblue {
    border-color: $color-lightblue;
  }

  &--lightgreen {
    border-color: $color-lightgreen;
  }

  &--pink {
    border-color: $color-pink;
  }

  &--green {
    border-color: $color-green;
  }

  &--yellow {
    border-color: $color-yellow;
  }

  &--blue {
    border-color: $color-blue;
  }
}
